import TemplateControl from "../../core/TemplateControl";
import { XNode } from "../../core/XNode";


export default class PopupWindow extends TemplateControl {

    static observedAttributes = ["title"];

    root: ShadowRoot;

    titleElement: HTMLElement;

    async prepare() {
        const root = this.root = this.attachShadow({ mode: "open"});

        XNode.append(root, <section part="root">
            <h3 part="title"></h3>
            <i event-click={() => this.remove()} part="close" class="fas fa-close"/>
            <slot part="content"></slot>
        </section>);

        this.titleElement = root.querySelector("h3");
        this.titleElement.textContent = this.getAttribute("title");

    }
    onChildAdded(child: Element) {
        child.setAttribute("part", "content");
    }
    onChildRemoved(child: HTMLElement) {
        child.removeAttribute("part");
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch(name) {
            case "title":
                if (this.titleElement) {
                    this.titleElement.textContent = this.getAttribute("title");
                }
                break;
        }
    }



}

customElements.define("popup-window", PopupWindow);
