import HtmlControl from "../../core/HtmlControl";
import { XNode } from "../../core/XNode";

class MarqueeText extends HtmlControl {

    prepare() {
        const text = this.textContent;
        this.textContent = "";
        // const root = this.attachShadow({ mode: "open"});

        XNode.render(this,<div>
            <marquee class="marquee">{text}</marquee>
        </div>);
       
    }


}

customElements.define("marquee-text", MarqueeText);