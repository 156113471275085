import { ComponentHelper } from "../../core/ComponentHelper";
import { sleep } from "../../core/sleep";


const nbsp = "\u202f";

class TypeWriter extends HTMLElement {

    stop = false;

    connectedCallback() {
        this.loop().catch(console.error);
    }

    disconnectedCallback() {
        this.stop = true;
    }

    async loop() {

        await ComponentHelper.waitForReady();

        this.setAttribute("translate", "false");

        const separator = this.getAttribute("separator") ?? this.getAttribute("data-separator") ?? ",";

        const typeDelay = Number(this.getAttribute("type-delay") ?? this.getAttribute("data-type-delay") ?? "180");

        const pause = Number(this.getAttribute("pause") ?? this.getAttribute("data-pause") ?? "700");

        const deleteDelay = Number(this.getAttribute("delete-delay") ?? this.getAttribute("data-delete-delay") ?? "100");

        const textList = this.textContent
            .split(separator)
            .map((x) => x.trim())
            .filter((x) => x);

        this.textContent = nbsp;


        let index = 0;
        const max = textList.length;

        while(!this.stop) {

            const current = textList[index];

            let length = 0;

            // forward...
            while(length < current.length) {
                this.textContent = current.substring(0, ++length) || nbsp;
                await sleep(typeDelay);
            }

            await sleep(pause);

            while(length) {
                this.textContent = current.substring(0, --length) || nbsp;
                await sleep(deleteDelay);
            }

            await sleep(deleteDelay);

            index++;
            if (index === max) {
                index = 0;
            }
        }
    }

}

customElements.define("type-writer", TypeWriter);
