import type ScrollAware from "./ScrollAware"

const aboveBody = Symbol("aboveBody");
const aboveHalf = Symbol("aboveHalf");
const elementVisible = Symbol("elementVisible");

/**
 * Ths reason this class exists is you cannot track visibility of items on page
 * based on slightest change, and which part of screen, like top, middle, bottom etc.
 */
export default class ScrollObserver {

    entries = new Set<ScrollAware>();

    private callbackSet = false;

    private timeout: any;

    observe(s: ScrollAware) {
        this.entries.add(s);
        if (!this.callbackSet) {
            this.callbackSet = true;
            window.addEventListener("scroll", this.queueScroll, { passive: true });
        }
        this.queueScroll();
    }

    unobserve(s: ScrollAware) {
        this.entries.delete(s);
        if (!this.entries.size) {
            if(this.callbackSet) {
                window.removeEventListener("scroll", this.queueScroll);
                this.callbackSet = false;
            }
        }
    }

    queueScroll = () => {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.onScroll, 1);
    }

    onScroll = (e: Event) => {
        this.timeout = void 0;
        const rootTop = Number.parseInt(document.body.style.getPropertyValue("--scroll-aware-top-margin") || "100px", 10);
        const rootMidTop = visualViewport.height / 2;
        const rootBottom = visualViewport.height;

        for (const entry of this.entries) {
            const { y, height, bottom } = entry.getBoundingClientRect();

            if (bottom < rootBottom) {
                // this is revealed...
                if (!entry[elementVisible]) {
                    entry.setAttribute("element-visible", "1");
                    entry[elementVisible] = true;
                }
            } else {
                if (entry[elementVisible]) {
                    entry.removeAttribute("element-visible");
                    entry[elementVisible] = void 0;
                }
            }

            if (y < rootMidTop) {
                if (!entry[aboveHalf]) {
                    entry.setAttribute("above-half-screen", "1");
                    entry[aboveHalf] = true;
                }
            } else {
                if (entry[aboveHalf]) {
                    entry.removeAttribute("above-half-screen");
                    entry[aboveHalf] = void 0;
                }
            }

            if (y < rootTop) {
                if (!entry[aboveBody]) {
                    entry.setAttribute("above-body", "1");
                    entry[aboveBody] = true;
                }
                if (bottom < rootTop) {
                    entry.style.setProperty("--above-body-ratio", "1");
                    entry.style.setProperty("--above-body-height", `${height}px` );
                } else {
                    const aboveBodyHeight = rootTop - y;
                    const aboveBodyRatio = aboveBodyHeight / height;
                    entry.style.setProperty("--above-body-ratio", `${aboveBodyRatio}`);
                    entry.style.setProperty("--above-body-height", `${aboveBodyHeight}px` );
                }
            } else {
                if (entry[aboveBody]) {
                    entry.removeAttribute("above-body");
                    entry[aboveBody] = void 0;
                }
            }

        }
    };

}