/**
 * Enumerate through all the descendants including self.
 * @param e Element
 */
export function *descendantElementIterator(e: Element, includeSelf = true) {
    const stack: Element[] = [];
    const start = e;
    if (!start) {
        return;
    }
    if (includeSelf) {
        stack.push(start);
    } else {
        stack.push(start.firstElementChild);
    }

    while (stack.length) {
        const pop = stack.pop();
        const next = pop.nextElementSibling;
        yield pop;

        if (next) {
            stack.push(next);
        }

        const child = pop.firstElementChild;
        if (child) {
            stack.push(child);
        }

    }
}
