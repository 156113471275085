import { ComponentHelper } from "../../core/ComponentHelper";
import HtmlControl from "../../core/HtmlControl";
import ScreenApi from "../../ScreenApi";
import "./ShareWindow";

const mailToRegex = /^mailto\:/i;

export default class ShareButton extends HtmlControl {

    prepare() {
        this.addEventListener("click", (e) => {

            const url = this.getAttribute("url") ?? this.getAttribute("href");
            const imageUrl = this.getAttribute("image-url");
            const title = this.getAttribute("subject") || this.getAttribute("title") || document.title || "";
            const shareTitle = this.getAttribute("share-title") || "Share";

            this.dispatchEvent(new CustomEvent("shareLink", {
                detail: {
                    url,
                    imageUrl,
                    title,
                    shareTitle
                },
                bubbles: true
            }))

        });
    }

}

ComponentHelper.waitForReady().then(() =>
document.body.addEventListener("shareLink", (e: CustomEvent) => {
    if (e.defaultPrevented) {
        return;
    }

    const { link, url = link, imageUrl, title, shareTitle = "Share On" } = e.detail;

    const isMailto = mailToRegex.test(url);
    if (ScreenApi.isMobile && !isMailto) {
        if (navigator.share) {
            navigator.share({ url, title }).catch(console.error);
            return;
        }
    }

    const popup = document.createElement("popup-window");
    const share = document.createElement("share-window");
    popup.setAttribute("title", shareTitle);
    if (isMailto) {
        share.setAttribute("mailto", "true")
    }
    share.setAttribute("url", url);
    if (imageUrl) {
        share.setAttribute("image-url", imageUrl);
    }
    if (title) {
        share.setAttribute("title", title);
    }
    
    popup.appendChild(share);

    document.body.appendChild(popup);

})
);

customElements.define("share-button", ShareButton);
