import HtmlControl from "../../core/HtmlControl";
import TemplateControl from "../../core/TemplateControl";
import { XNode } from "../../core/XNode";

export default class RippleBox extends HtmlControl {

    root: ShadowRoot;

    async prepare() {
        if (this.isContentEditable) {
            return;
        }
        XNode.render(this, <div>
            <animated-box animation="ripple-glow-and-fade" animation-delay="1s"/>
            <animated-box animation="ripple-glow-and-fade" animation-delay="2s"/>
            <animated-box animation="ripple-glow-and-fade" animation-delay="3s"/>
        </div>);
    }

}

customElements.define("ripple-box", RippleBox);
