import ImageTagHelper from "../../core/ImageTagHelper";
import TemplateControl from "../../core/TemplateControl";

let id = 1;

class ImageGrid extends TemplateControl {

    onChildAdded(child: HTMLElement) {
        const dataOnReveal = this.getAttribute("styler-on-enter") ?? "zoom-in";
        child.setAttribute("styler-on-enter", dataOnReveal);

        if (child.tagName === "IMG") {
            const {thumb } = ImageTagHelper.getUrls(child);
            if (thumb !== child.getAttribute("src")) {
                child.setAttribute("src", thumb);
            }
        }
    }
    onChildRemoved(child: Node) {
    }

    onClickEvent = (e: MouseEvent) => {
        let start = e.target as HTMLElement;
        while(start && !/^(video|img)/i.test(start.tagName)) {
            start = start.parentElement;
        }
        if (!start) {
            return;
        }

        const fullScreen = document.createElement("full-screen-image-gallery");

        const { children } = this;

        let selected = null;

        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            const newChild = element.cloneNode(true) as HTMLElement;
            if (element === start) {
                selected = newChild;
            }
            if (newChild.tagName === "IMG") {
                const { full } = ImageTagHelper.getUrls(newChild);
                newChild.setAttribute("src", full);
            }
            fullScreen.appendChild(newChild);
        }
        fullScreen.style.position = "fixed";
        fullScreen.style.width = "100%";
        fullScreen.style.height = "100%";
        (fullScreen as any).selected = selected;
        document.body.appendChild(fullScreen);
    };

    async prepare() {

        this.id ||= `img_grid_${id++}`;

        this.addEventListener("click", this.onClickEvent);
    }

}

customElements.define("image-grid", ImageGrid);