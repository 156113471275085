import HtmlControl from "../../core/HtmlControl";
import { XNode } from "../../core/XNode";


let langID = 1;

const getName = (code: string) => {
    try {
        return (new Intl.DisplayNames([code], { type: 'language' })).of(code);
    } catch (error) {
        return code;
    }
};

const installLanguageStyle = (code: string) => {

    if(document.querySelector(`[data-lang-selector="${code}"]`)) {
        return;
    }

    const style = document.createElement("style");
    style.setAttribute("data-lang-selector", code);
    style.textContent = `body[data-lang=${code}] *[lang]:not([lang=${code}]) { display: none; }`;
    document.head.appendChild(style);


};

export default class LanguageChooser extends HtmlControl {

    root: ShadowRoot;

    editing = false;

    async prepare() {

        let langs = this.getAttribute("languages");

        if (!langs) {
            // detect langs...
            console.warn(`Attribute languages not set, set to disable language detection.`)
            const ls = new Set();
            for (const element of Array.from(document.querySelectorAll(`[lang]`))) {
                const l = element.getAttribute("lang");
                ls.add(l.trim().toLocaleLowerCase());
            }
            langs = Array.from(ls).join(",");
        }

        const root = this.root = this.attachShadow({ mode: "open"});

        const container = XNode.append(root, <div part="root">
            <i part="icon" class="fa-solid fa-globe"/>
            <select part="select"/>
        </div>);        

        const select = container.querySelector("select");

        let defaultLanguage = document.body.getAttribute("data-lang");

        const editing = this.editing = (window as any).editingMode || this.isContentEditable || document.body.getAttribute("editing") === "true";

        if (editing) {
            defaultLanguage = "*";
        }

        // we will set body's language if not set...
        for (const element of langs.split(/[,\s\;]/i)) {
            const lang = element.trim();
            if (!lang) {
                continue;
            }
            if (!editing) {
                installLanguageStyle(lang);
            }

            const option = document.createElement("option");
            option.value = lang;
            option.textContent = getName(lang);
            select.appendChild(option);

            if (!editing) {
                defaultLanguage = lang;
                document.body.setAttribute("data-lang", lang);
            }
        }

        if (editing) {
            return;
        }

        const selected = localStorage.getItem("website-lang");
        if (selected) {
            select.value = selected;
            document.body.setAttribute("data-lang", selected);
        }

        select.oninput = () => {
            document.body.setAttribute("data-lang", select.value);
            localStorage.setItem("website-lang", select.value);
        };

        const label = root.querySelector("i");
        label.onclick = () => select.showPicker?.();
    }


}

customElements.define("language-chooser", LanguageChooser);
