import { ComponentHelper } from "../../core/ComponentHelper";
import HtmlControl from "../../core/HtmlControl";
import ScreenApi from "../../ScreenApi";

export default class MenuItem extends HtmlControl {


    async prepare() {

        if (this.isContentEditable) {
            return;
        }

        await ComponentHelper.waitForReady();


        const checkbox = document.createElement("input");
        checkbox.type = "checkbox";
        let div = document.createElement("div");
        div.className = "image-div";
        this.insertAdjacentElement("afterbegin", checkbox);
        this.insertBefore(div, checkbox.nextSibling);

        if (!ScreenApi.isMobile) {
            return;
        }

        this.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;
            
            const anchor = ComponentHelper.findAncestorOrSelf(target, (x) => x.previousElementSibling === div);

            if (anchor) {
                checkbox.checked = !checkbox.checked;
            }
            

        });
    }
}

export class MenuItems extends HTMLElement {

}

customElements.define("menu-item", MenuItem);
customElements.define("menu-items", MenuItems);
