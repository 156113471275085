import { IEditableAttribute, IEditableElement } from "../../core/EditableAttributes";
import ScrollObserver from "./ScrollObserver";

const bodyObserverCallback = (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
        const element = entry.target as HTMLElement;
        element.style.setProperty("--body-ratio", `${entry.intersectionRatio}`);
        if (entry.isIntersecting) {
            const h = entry.boundingClientRect.height;
            element.style.setProperty("--body-height", `${h}`);
        }
    }
};

const aboveBodyObserverCallback = (entries: IntersectionObserverEntry[]) => {

    if (!entries.length) {
        return;
    }

    const { rootBounds } = entries[0];

    const { y: rootY, bottom: rootBottom, height: rootHeight } = rootBounds;

    for (const entry of entries) {

        const element = entry.target as HTMLElement;

        const { boundingClientRect } = entry;
        const { y, height, bottom } = boundingClientRect;

        const aboveBodyHeight = rootY - y;

        const aboveBodyRatio = (bottom) < rootY
            ? 1
            : aboveBodyHeight / height;

        element.style.setProperty("--above-body-ratio", `${aboveBodyRatio}`);
        element.style.setProperty("--y", `${y}`);
        element.style.setProperty("--bottom", `${bottom}`);
        element.style.setProperty("--root-y", `${rootY}`);
        element.style.setProperty("--root-height", `${rootHeight}`);
        element.style.setProperty("--root-bottom", `${rootBottom}`);
        if (y < rootY ) {
            element.style.setProperty("--above-body-height", `${aboveBodyHeight}`);
            element.setAttribute("above-body", "yes");
        } else {
            element.removeAttribute("above-body");
        }

        if (y < rootBottom) {
            element.setAttribute("above-half-screen", "1");
        } else {
            element.removeAttribute("above-half-screen");
        }
    }
};

// const belowBodyObserverCallback = (entries: IntersectionObserverEntry[]) => {
//     for (const entry of entries) {
//         const element = entry.target as HTMLElement;
//         element.style.setProperty("--below-body-ratio", `${entry.intersectionRatio}`);
//         if (entry.isIntersecting) {
//             const h = entry.boundingClientRect.height;
//             element.style.setProperty("--below-body-height", `${h}`);
//             element.setAttribute("below-body", "yes");
//         } else {
//             element.removeAttribute("below-body");
//         }
//     }
// };

const scrollObserver = new ScrollObserver();

export default class ScrollAware extends HTMLElement{

    connectedCallback() {
        if (this.isContentEditable) {
            return;
        }
        scrollObserver.observe(this);
    }

    disconnectedCallback() {
        if (this.isContentEditable) {
            return;
        }
        scrollObserver.unobserve(this);
    }

}

customElements.define("scroll-aware", ScrollAware);