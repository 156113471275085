export default class ImageTagHelper {

    static getUrls(iterator: HTMLElement) {
        let full = iterator.getAttribute("src");
        let thumb = full;
        const largeSrc = iterator.getAttribute("large-src");
        const thumbSrc = iterator.getAttribute("thumb-src");
        if (largeSrc) {
            full = largeSrc;
            return { full , thumb };
        }
        if (thumbSrc) {
            thumb = thumbSrc;
            return { full, thumb};
        }
        const set = iterator.getAttribute("data-src-set")
            ?? iterator.getAttribute("data-srcset")
            ?? iterator.getAttribute("srcset");
        if (set) {
            const srcSet = set
                .split(",")
                .map((x) => x.trim().split(" "))
                .sort(([aSrc, aSize], [bSrc, bSize]) => Number.parseFloat(bSize)-Number.parseFloat(aSize));
            full = srcSet[0][0];
            thumb = srcSet.pop()[0];
        }
        return { full, thumb };
    }

}