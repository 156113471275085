import { ComponentHelper } from "../core/ComponentHelper";
import { descendantElementIterator } from "../core/descendantElementIterator";

export const reveal = new IntersectionObserver((entries) => {

    for (const iterator of entries) {
        const { target } = iterator;
        if(iterator.isIntersecting) {
            target.setAttribute("styler-entered", "true");
        } else {
            target.setAttribute("styler-left", "true");
            setTimeout(() => target.removeAttribute("styler-left"));
        }
    }

});

ComponentHelper.waitForReady().then(() => {
    for (const element of descendantElementIterator(document.body)) {
        if(element.hasAttribute("styler-on-enter")) {
            reveal.observe(element);
        }
    }
    // observe mutations...
});