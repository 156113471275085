import "./components/popup/PopupWindow";
import "./components/animated-text/AnimatedText";
import "./components/carousel-slider/CarouselSlider";
import "./components/typewriter/TypeWriter";
import "./components/image-gallery/ImageGallery";
import "./components/image-grid/ImageGrid";
import "./components/video-player/VideoPlayer";
import "./components/pdf-flip-book/PdfFlipBook";
import "./components/pdf-viewer/PdfFrame";
import "./components/pdf-viewer/PdfPage";
import "./components/pdf-viewer/PdfViewer";
import "./components/language-chooser/LanguageChooser";
import "./components/nav-bar/NavBar";
import "./components/menu-bar/MenuBar";
import "./components/menu-item/MenuItem";
import "./components/marquee-text/MarqueeText";
import "./components/image-carousel/ImageCarousel";
import "./components/horizontal-list/HorizontalList";
import "./components/full-screen-image-gallery/FullScreenImageGallery";
import "./components/qr-code-link/QRCodeLink";
import "./components/share-button/ShareButton";
import "./components/share-button/ShareWindow";
import "./components/flip-box/FlipBox";
import "./components/animated-box/AnimatedBox";
import "./components/ripple-box/RippleBox";
import "./components/inline-svg/InlineSvg";
import "./components/scroll-aware/ScrollAware";
import { installCss } from "./core/installCss";

import "./intersection/intersection";

installCss("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free", "@6.7.2/css/all.min.css");

declare let __webpack_public_path__: any;

const link = document.createElement("link");
link.rel = "stylesheet";
link.href = new URL("./index.less.css", __webpack_public_path__ ?? (document.currentScript as any).src).toString();
document.head.insertAdjacentElement("afterbegin", link);

(window as any).socialMailWebContentLoaded = true;