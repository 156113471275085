import { ComponentHelper } from "../../core/ComponentHelper";
import { installCss } from "../../core/installCss";
import Modules from "../../core/Modules";
import { sleep } from "../../core/sleep";


export class PdfFlipBook extends HTMLElement {

    pageFlip;

    connectedCallback() {
        this.prepare().catch(console.error);
    }

    async prepare() {

        this.innerHTML = "....";

        installCss("https://cdn.jsdelivr.net/npm/page-flip", "@2.0.7/src/Style/stPageFlip.css");

        const src = this.getAttribute("src");

        await ComponentHelper.waitForReady();

        const pdfModule = Modules.import("https://mozilla.github.io/pdf.js/build/pdf.mjs");

        const { PageFlip } = await Modules.import("https://cdn.jsdelivr.net/npm/page-flip@2.0.7/dist/js/page-flip.module.js");

        await pdfModule;

        let { pdfjsLib } = globalThis as any;
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://mozilla.github.io/pdf.js/build/pdf.worker.mjs';

        const pdfDoc = await pdfjsLib.getDocument(src).promise;

        const container = document.createElement("section");

        const firstPage = await pdfDoc.getPage(1);
        const viewport = firstPage.getViewport({ scale: 1});

        let { width, height } = viewport;

        await sleep(100);

        const { clientWidth, clientHeight } = this;

        if(clientWidth >= clientHeight) {

            if (height > width) {
                width = Math.floor(width * clientHeight/height);
                height = clientHeight;
            } else {
                height = Math.floor(height * clientWidth/width);
                width = clientWidth;
            }
            
        } else {
            if (width < height) {
                width = Math.floor(width * clientHeight/height);
                height = clientHeight;
            } else {
                height = Math.floor(height * clientWidth/width);
                width = clientWidth;
            }

        }

        const tasks = [];

        for(let i=1; i<=pdfDoc.numPages;i++) {

            const page = await pdfDoc.getPage(i);

            const div = document.createElement("div");
            const canvas = document.createElement("canvas");
            div.appendChild(canvas);
            container.appendChild(div);
            div.className = "page";

            const canvasContext = canvas.getContext("2d");
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            tasks.push(page.render({
                canvasContext,
                viewport
            }).promise);

        }

        await Promise.all(tasks);

        // container.appendChild(book);

        this.innerHTML = "";

        this.appendChild(container);

        await sleep(1);

        this.pageFlip = new PageFlip(container, {
            width,
            height,
            size: "fixed",
            // set threshold values:
            minWidth: Math.floor(width/2),
            maxWidth: (width*2),
            minHeight: Math.floor(height/2),
            maxHeight: (height*2),

            maxShadowOpacity: 0.5, // Half shadow intensity
            showCover: true,
            mobileScrollSupport: false // disable content scrolling on mobile devices
        });

        await sleep(10);

        this.pageFlip.loadFromHTML(this.querySelectorAll(".page"));

    }


}

customElements.define("pdf-flip-book", PdfFlipBook);