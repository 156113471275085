import HtmlControl from "../../core/HtmlControl";
import ScriptInstaller from "../../core/ScriptInstaller";
import { XNode } from "../../core/XNode";

declare let QRCode: any;


export default class QRCodeLink extends HtmlControl {
    
    async prepare() {
        
        const link = this.getAttribute("href");

        if (this.isContentEditable) {
            return;
        }

        // if(/mobile|iOS|iPhone|Android/i.test(navigator.userAgent)) {
        //     // this is mobile...
        //     const a = document.createElement("a");
        //     a.href = link;
        //     a.target = "_blank";
        //     a.textContent = this.textContent;
        //     this.innerHTML = a.outerHTML;
        //     return;
        // }

        await ScriptInstaller.install("https://cdn.jsdelivr.net/npm/qrcodejs@1.0.0/qrcode.js");

        this.addEventListener("click", () => {

            const popup = document.createElement("popup-window");
            popup.setAttribute("title", this.textContent);
            const root = XNode.append(popup, <div style="display: flex; flex-direction: column; gap: 10px;">
                <div></div>
                <div style="display: flex; gap: 10px; align-items: center; font-size: larger;">
                    <a class="save" download="download" style="color: inherit; border: none; text-decoration: none;"><i style="cursor:pointer" class="fas fa-save"/> Save</a>
                    <a style="color: inherit;border: none; text-decoration: none;margin-left: auto;" href={link} target="_blank">Open Link</a>
                </div>
            </div>);
            document.body.appendChild(popup);

            setTimeout(() => {
                const qr = new QRCode(root.firstElementChild, {
                    text: link,
                    width: 256,
                    height: 256,
                });

                setTimeout(() => {
                    const generated = root.querySelector("img");
                    const save = root.querySelector("a.save");
                    save.setAttribute("href", generated.src);
                }, 100);

            }, 100);

        });

    }    

}

customElements.define("qr-code-link", QRCodeLink);