import ImageTagHelper from "../../core/ImageTagHelper";
import TemplateControl from "../../core/TemplateControl";
import { XNode } from "../../core/XNode";
import { reveal } from "../../intersection/intersection";

let id = 1;

let imgID = 1;

class ImageCarousel extends TemplateControl {

    container: HTMLElement;

    contentClick = (e: MouseEvent) => {
        
        if (this.isContentEditable) {
            return;
        }

        let start = e.target as HTMLElement;
        while (start && !start.assignedSlot) {
            start = start.parentElement;
        }
        if (!start) {
            return;
        }
        const fullScreen = document.createElement("full-screen-image-gallery");

        const { children } = this;

        let selected = null;

        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            const newChild = element.cloneNode(true) as HTMLElement;
            if (element === start) {
                selected = newChild;
            }
            if (newChild.tagName === "IMG") {
                const { full } = ImageTagHelper.getUrls(newChild);
                newChild.setAttribute("src", full);
            }
            fullScreen.appendChild(newChild);
        }
        fullScreen.style.position = "fixed";
        fullScreen.style.width = "100%";
        fullScreen.style.height = "100%";
        (fullScreen as any).selected = selected;
        document.body.appendChild(fullScreen);
    };

    async prepare() {

        this.id ||= `img_carousel_${id++}`;

        const root = this.attachShadow({ mode: "open", delegatesFocus: true });


        XNode.append(root, <div part="root">
            <div part="left" event-click={() => this.move(-1)}/>
            <div part="content" event-click={this.contentClick}/>
            <div part="right" event-click={() => this.move(1)}/>
        </div>);

        this.container = root.querySelector(`[part="content"]`)
    }
    
    move(n: number) {
        const { container } = this;

        container.scrollBy({
            left: n * container.offsetWidth,
            behavior: "smooth"
        });
    }

    onChildAdded(child: HTMLElement) {

        const dataOnReveal = this.getAttribute("styler-on-enter") ?? "zoom-in";

        const name = `ic1-id-${imgID++}`;
        XNode.append(this.container, <slot part="img" name={name} />);

        if (child.tagName === "IMG") {
            const {thumb } = ImageTagHelper.getUrls(child);
            child.setAttribute("src", thumb);
        }
        child.setAttribute("slot", name);
        child.setAttribute("styler-on-enter", dataOnReveal);
        reveal.observe(child);
    }
    onChildRemoved(child: HTMLElement) {
        child.assignedSlot?.remove();
        reveal.unobserve(child);
    }



}

customElements.define("image-carousel", ImageCarousel);