import { IEditableAttribute } from "../../core/EditableAttributes";
import TemplateControl from "../../core/TemplateControl";
import { XNode } from "../../core/XNode";

export default class FlipBox extends TemplateControl {

    static observedAttributes = ["first-visible"];

    get editableAttributes(): IEditableAttribute[] {
        return [
            { name: "first-visible", type: "boolean" }
        ];
    }
    
    root: ShadowRoot;

    container: HTMLElement;

    i = 0;

    async prepare() {
        // do nothing
        const root = this.root = this.attachShadow({ mode:"open", delegatesFocus: true });
        XNode.append(root, <div part="root">
            <div part="container"></div>
            <div part="toggle" title="Toggle" event-click={ () => this.container.part.toggle("hover") }>
            </div>
        </div>);

        this.container = root.querySelector(`[part="container"]`);

        this.addEventListener("click", () => {
            if (this.isContentEditable) {
                return;
            }
            this.container.part.toggle("hover");
        });
    }
    onChildAdded(child: HTMLElement) {
        // do nothing
        const name = `child-${++this.i}`;
        XNode.append(this.container, <slot part={name} name={name} />);

        child.setAttribute("slot", name);
    }
    onChildRemoved(child: HTMLElement) {
        // do nothing
        child.assignedSlot?.remove();
    }


}

customElements.define("flip-box", FlipBox);
